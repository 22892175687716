<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="quotations" ref="dt" v-model:filters="filters1" v-model:selection="selectedRow"
                    selectionMode="single" :metaKeySelection="false" :filters="filters1" filterDisplay="menu"
                    dataKey="id" :globalFilterFields="['quotno','companyname']" scrollable :tableStyle="styleHeight"
                    :scrollHeight="sHeight" responsiveLayout="scroll" :loading="loading" :paginator="true" :lazy="true"
                    :rows="pagesize" :virtualScrollerOptions="vsOptionFun()" :rowsPerPageOptions="[50, 100, 200, 500]"
                    :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                                @click="clearFilter1()" />
                            <span class="p-input-icon-left mb-2" style="width:40%">
                                <div class="grid">
                                    <div class="col-4">
                                        <InputText v-model="filters1['global'].value" placeholder="输入报价单号或对方公司模糊查询"
                                            style="width:100%" />
                                    </div>
                                    <div class="col-5">
                                        <InputText v-model="skuval" placeholder="输入报价产品SKU/MPN模糊查询"
                                            style="width:100%" />
                                    </div>
                                    <div class="col-3">
                                        <Button type="button" icon="pi pi-search" label="查询" @click="customFilter();"
                                            class="p-button-outlined mb-2" />
                                    </div>
                                </div>
                            </span>
                        </div>
                    </template>
                    <Column selectionMode="single" frozen bodyStyle="width:3em;flex:0;" headerStyle="width:3em;flex:0;">
                    </Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :showFilterMenu="col.showFilterMenu" :showFilterMatchModes="col.showFilterMenu"
                        :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink || col.oneline || col.isimage" #body="slotProps">
                            <Button v-if="col.islink" :label="slotProps.data.quotno"
                                @click="openQuotation(slotProps.data)" class="p-button-link" />
                            <label v-if="col.oneline" class="oneline"
                                v-tooltip.top="{ value: slotProps.data[col.field], disabled: false }">{{slotProps.data[col.field]}}</label>
                            <Image v-if="col.isimage" :src="getFilePatch(slotProps.data[col.field])" width="50"
                                preview />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :CellEditCompleteMethod="cellEditComplete"
                :childNewDataConfig="childNewDataConfig" :getChildTableColWidthMehod="getChildTableColWidth"
                :childTableFooter="childTableFooter" :closeButLoadingMethod="closeButLoading" :currentRow="currentRow"
                :freshListMethod="freshList" :pageJsonConfig="pageJsonConfig" :loadDataAfterMethod="loadDataAfter"
                :childExpandePageConfig="childExpandeConfig" :closeParentMehod="closeMaximizable"
                :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button :label="currentState" icon="pi pi-sitemap" class="p-button-raised p-button-text mr-2"
                    @click="openLeftPosition" />
                <Button :label="editHistoryButTxt" icon="pi pi-tags" class="ml-auto" @click="showEditHistory" />
                <Button label="关闭" icon="pi pi-power-off" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="提交" icon="pi pi-check" @click="submitData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="流程信息" v-model:visible="displayPosition" :style="{width: '50vw'}" :position="position"
        :maximizable="true" :modal="true">
        <FlowHistory ref="flowhistory" :flowHistoryData="flowhistoryJson" :title="recordsubject" />
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closePosition" autofocus />
        </template>
    </Dialog>
    <Dialog header="数据导出" v-model:visible="importExcelDisplay" :style="{width: '30vw'}" :maximizable="false"
        :modal="true">
        <div class="grid p-fluid mt-3">
            <div v-if="importFinish" class="field col-12 md:col-12">
                <Button label="数据处理完成,请点击下载" @click="getExcelFilePatch" class="p-button-link" />
            </div>
            <div v-if="!importFinish" class="field col-12 md:col-12">
                <ProgressBar mode="indeterminate" style="height: .5em" />
            </div>
            <div v-if="!importFinish" class="field col-12 md:col-12">
                <label>数据处理中....</label>
            </div>
        </div>
    </Dialog>
</template>
<style type="text/css">
    .oneline {
        width: 160px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import {
        FilterMatchMode,
    } from 'primevue/api';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import ConfigService from '../../../service/ConfigService';
    import CommonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import FlowHistory from '../../../components/FlowHistory.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    // import quotationgePageJson from "@/data/quotationConfig.json";
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '743';
            const listcolumns = ref();
            return {
                confirm,
                currentMenuId,
                listcolumns
            };
        },
        data() {
            return {
                skuval: '',
                editHistoryButTxt: '显示修改痕迹',
                currentPage: 1,
                newId: 2,
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                pageJsonConfig: {},
                butLoading: false,
                recordsubject: '',
                readOnly: true,
                displayMaximizable: false,
                displayQueryPlan: false,
                displayPosition: false,
                importExcelDisplay: false,
                importFinish: false,
                excelFilePatch: '',
                position: 'bottomleft',
                currentState: '流程信息',
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                currentRow: {
                    bindpage: 'quotation',
                    id: '-1',
                    workflowid: '',
                    flowguid: 'Quotation',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                currencyDict: [],
                flowhistoryJson: null,
                uploadurl: '',
                selectedRow: ref(),
                selectedProductRow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                listTitle: '报价列表',
                selectedKey: {
                    743: true
                },
                expandedKey: null,
                childExpandeConfig: {},
                quotations: [],
                inspectiondata: {
                    'explain': '',
                    files: []
                },
                loading: true,
                nodes: [],
                items: [{
                    label: '报价操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                        label: '报价单打印',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => {
                            this.exportQuotation();
                        }
                    }, {
                        separator: true
                    }, {
                        label: '新建报价',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => {
                            window.location.href =
                                '/#/crmquotation/quotationdraftbox?createquotation=1';
                        }
                    }, ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
                childNewDataConfig: {
                    products: false,
                },
                childTableFooter: {
                    products: {
                        url: '合计',
                        loadingqty: 0,
                        price: 0,
                        grossweight: 0,
                        netweight: 0
                    }
                },
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
        },
        methods: {
            init() {
                this.skuval = '';
                this.configService.getquotation(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.page;
                    this.childExpandeConfig = data.page['region_3']['left']['element'][0][
                        'childExpandeConfig'
                    ];
                    this.uploadurl = '../calf/plat/PlatUpload?appkey=' + sessionStorage.getItem("appkey");
                    this.initFilters1();
                    this.loadMenuTree();
                    CommonJs.loadDictFromCache('Currency', 'dictionaryCurrency', this.currencyDict, this
                        .setloadDictFromCache);
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                });
            },
            vsOptionFun() {
                console.log('vsOptionFun...');
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            setloadDictFromCache(v_currencyDict) {
                this.currencyDict = v_currencyDict;
            },
            getNewId() {
                var id = -1 * this.newId;
                this.newId = this.newId + 1;
                return id;
            },
            initFilters1() {
                this.skuval = '';
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('741', this.currentMenuId, this.$route, this
                    .loadData, this.setlistTitleFun, this.setloadMenuTree);
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '报价列表', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.closeQueryPlanFun(false);
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.currentPage = v_page;
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'approvedbox',
                        value: '',
                        operation: '='
                    }, {
                        name: 'sku',
                        value: this.skuval,
                        operation: ''
                    }]
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'quotno,companyname',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{quotationlist(where:" + JSON.stringify(listwhere) +
                    "){id companyname quotno address quotationdate linkone emailone mobileone faxone linktwo emailtwo mobiletwo faxtwo paymentterm paymenttermname moq deliverytime tradeterms approvestatus approvestatusname flowid workflowid boxid stateid currentstatename }}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.quotations = jsonData.data.quotationlist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                    }
                });
            },
            openQuotation(v_data) {
                this.editHistoryButTxt = '显示修改痕迹';
                this.readOnly = true;
                this.currentState = '流程信息';
                this.recordsubject = '报价单' + v_data.quotno;
                this.childTableFooter = {
                    products: {
                        url: '合计',
                        loadingqty: 0,
                        price: 0,
                        grossweight: 0,
                        netweight: 0
                    }
                };
                this.currentRow = {
                    bindpage: 'quotation',
                    id: v_data.id,
                    workflowid: v_data.workflowid,
                    flowguid: 'Quotation',
                    boxid: v_data.boxid,
                    stateid: v_data.stateid,
                    flowid: v_data.flowid,
                };
                this.displayMaximizable = true;
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            freshList() {
                this.butLoading = false;
                this.loadData(this.currentPage);
            },
            submitData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo();
                this.editHistoryButTxt = '显示修改痕迹';
            },
            getFilePatch(v_patch) {
                return '/calf/plat/file/' + v_patch + '?appkey=' + sessionStorage.getItem("appkey");
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            openLeftPosition() {
                console.log('recordsubject:' + this.recordsubject);
                this.flowhistoryJson = this.$refs.universalpage.flowhistory;
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            cellEditComplete(tablename, field, data, rowdata, childTableFooterObj) {
                console.log('cellEditCompleteMethod..');
                console.log(tablename);
                console.log(field);
                console.log(data);
                console.log(rowdata);
                console.log(childTableFooterObj);
            },
            getChildTableColWidth(v_childname, v_fieldname) {
                if (v_childname == 'products' && v_fieldname == 'description') {
                    return 'width:200px;';
                } else {
                    return 'width:150px;';
                }
            },
            exportQuotation() {
                if (!this.selectedRow) {
                    MessageTip.warnmsg('请选择要打印的报价单');
                    return;
                }
                var exportInput = {
                    templateId: '279288984305733',
                    appKey: sessionStorage.getItem("appkey"),
                    conditions: [{
                        schemaName: 'quotation',
                        conditionValue: [{
                            name: 'id',
                            value: this.selectedRow['id'],
                            operation: '='
                        }, {
                            name: 'export',
                            value: '',
                            operation: '='
                        }],
                    }],
                };
                this.excelFilePatch = '';
                this.importFinish = false;
                this.importExcelDisplay = true;
                requests.create_plat_requests(sessionStorage.getItem("corpid"), sessionStorage.getItem("storeid")).post(
                        '/calf/plat/DecisionAnalysisExport?appkey=' + sessionStorage.getItem("appkey"), exportInput)
                    .then(
                        res => {
                            console.log(res);
                            if (res.data.errcode == "0") {
                                var retObj = JSON.parse(unescape(Base64.decode(res.data.data)));
                                console.log('exportQuotation:');
                                console.log(retObj);
                                this.excelFilePatch = retObj.filepatch;
                                this.importFinish = true;
                            } else {
                                this.importExcelDisplay = false;
                                MessageTip.warnmsg(res.data);
                            }
                        });
            },
            loadDataAfter(v_mainelements, v_childelements, v_originalData, v_noshowelements, v_childExpandeConfig) {
                console.log('loadDataAfter...');
                console.log(v_mainelements);
                console.log(v_childelements);
                console.log(v_originalData);
                console.log(v_noshowelements);
                console.log(this.selectedHistoryRow);
                for (var k = 0; k < v_mainelements.length; k++) {
                    if (v_mainelements[k]['name'] == 'currency') {
                        this.currencyChange(v_childelements, v_mainelements[k]['val'], this.currencyDict,
                            v_childExpandeConfig);
                        break;
                    }
                }
            },
            currencyChange(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
                var ename = '';
                for (var i3 = 0; i3 < v_dictsource.length; i3++) {
                    if (v_dictsource[i3].code == v_val) {
                        ename = v_dictsource[i3].ename;
                        break;
                    }
                }
                var loopcnt = 0;
                var isbreak = false;
                for (var i = 0; i < v_childelements.length; i++) {
                    for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                        if (v_childelements[i].columns[i2].field == 'price') {
                            v_childelements[i].columns[i2].header = '价格(' + ename + ')';
                            loopcnt++;
                        }
                        if (loopcnt == 1) {
                            isbreak = true;
                            break;
                        }
                    }
                    if (isbreak) {
                        break;
                    }
                }
                if (v_childExpandeConfig) {
                    for (var i4 = 0; i4 < v_childExpandeConfig.products.config[0].columns.length; i4++) {
                        if (v_childExpandeConfig.products.config[0].columns[i4].field == 'price') {
                            v_childExpandeConfig.products.config[0].columns[i4].header = '价格(' + ename + ')';
                        }
                    }
                } else {
                    for (var i5 = 0; i5 < this.childExpandeConfig.products.config[0].columns.length; i5++) {
                        if (this.childExpandeConfig.products.config[0].columns[i5].field == 'price') {
                            this.childExpandeConfig.products.config[0].columns[i5].header = '价格(' + ename + ')';
                        }
                    }
                    this.$refs.universalpage.setchildExpandeConfig(this.childExpandeConfig);
                }
            },
            getExcelFilePatch() {
                location.href = '/calf/plat/file/' + this.excelFilePatch + '?appkey=' + sessionStorage.getItem(
                    "appkey");
                return;
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            dialogClose() {
                this.butLoading = false;
            },
            showEditHistory() {
                if (this.editHistoryButTxt == '隐藏修改痕迹') {
                    this.$refs.universalpage.clearEditHistory();
                    this.editHistoryButTxt = '显示修改痕迹';
                    return;
                }
                this.$refs.universalpage.showEditHistory(this.seteditHistoryButTxt);
            },
            seteditHistoryButTxt() {
                this.editHistoryButTxt = '隐藏修改痕迹';
            }
        },
        components: {
            NewUniversalPage,
            FlowHistory,
            'AppFooter': AppFooter,
        }
    }
</script>
<style lang="scss" scoped>
    .product-image {
        width: 50px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
    }

    .orders-subtable {
        padding: 1rem;
    }
</style>